

































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import {GrupoResiduoService, GrupoServicoService, ResiduoService, TecnologiaService} from '@/core/services/residuo';
import {GrupoResiduo, Residuo, Tecnologia} from '@/core/models/residuo';
import { Contato, PageBase } from '@/core/models/shared';

@Component
export default class ListaResiduo extends PageBase {
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Código', value: 'codigo' },
    { text: 'Nome', value: 'nome' },
    { text: 'Grupo', value: 'grupo.nome' },
    { text: 'Tecnologia', value: 'tecnologia.nome' },
    { text: 'Unidade Medida', value: 'unidadeMedida.nome' },
    { text: 'Preço', value: 'preco' },
    { text: 'Ativo', value: 'ativo' },
  ];
  item = new Residuo();
  service = new ResiduoService();

  tecnologias: any[] = [];
  tecnologiaService = new TecnologiaService();

  grupos: any[] = [];
  grupoService = new GrupoResiduoService();

  situacaoResiduo: any = [];  
  sheet: boolean = false;
  filtro: any = {
    nome: '',
    grupoId: 0,
    tecnologiaId: 0,
    unidadeMedidaId: 0,
    ativo: null
  }

  CarregaTecnologia(){
    this.tecnologiaService.ListarTudo()
      .then(
        res=>{
          this.tecnologias = res.data.items;
          // this.tecnologias = this.tecnologias.filter(x => x.pcp == false);
          this.tecnologias.unshift({
              id: 0,
              nome: 'Todos'
          });;
        },
        err=>{
          if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else {
          this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
  }

  CarregaGrupoResiduo(){
    this.grupoService.ListarTudo()
      .then(
        res=>{
          this.grupos = res.data.items;
          this.grupos.unshift({
              id: 0,
              nome: 'Todos'
          });;
        },
        err=>{
          if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else {
          this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
  }

  @Watch('options', { deep: true })
  Atualizar(isFiltro: boolean = false){

    if (isFiltro == true) {
      this.options.page = 1;
    }

    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
    this.sheet = false;

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, null, this.headers, this.service.MontaFiltro(this.filtro), 'Grupo, Tecnologia, UnidadeMedida, Armazenamento, Acondicionamento').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
    .finally(() => (this.loading = false));
  }

  CarregarSituacao()
  {
    this.situacaoResiduo.push(
      { nome: 'Todos', valor: null },
      { nome: 'Ativo', valor: true },
      { nome: 'Inativo', valor: false }
    );
  }

  mounted(){
    this.CarregarSituacao();
    this.CarregaTecnologia();
    this.CarregaGrupoResiduo();
  }

  AbrirDialogCadastro(item?: Residuo){
    if(item){
      this.service.ObterPorId(item.id, 'Grupo, Tecnologia, UnidadeMedida').then(
        res=>{
          this.item = res.data;
          this.dialogCadastro = true;
        },
        err => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
    } 
    else{
      this.item = new Residuo();
      this.item.empresaId = this.app.empresaId;
      this.dialogCadastro = true;
    }
  }

  Excluir(item: Residuo){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          return this.service.Excluir(item.id)
                  .then(res => {
                      if (res.status == 200){
                          return res.data;
                      }
                  },
                  err => {
                    if (err.response.status == 403){
                      this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
                    }
                    else{
                      this.$swal('Aviso', err.response.data, 'error')
                    }
                  }
              )
          },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", result.value, "success");
              this.Atualizar();
          }
      })
  }

}

